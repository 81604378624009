export const RESET_ERROR = 'RESET_ERROR';
export const LOADING = 'LOADING';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';
export const PROFILE_LIST_SUCCESS = 'PROFILE_LIST_SUCCESS';
export const PROFILE_LIST_FAIL = 'PROFILE_LIST_FAIL';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';

export const SUBUSER_UPDATE_SUCCESS = 'SUBUSER_UPDATE_SUCCESS';
export const SUBUSER_UPDATE_FAIL = 'SUBUSER_UPDATE_FAIL';
