import React from 'react'
import { FormGroup } from '@mui/material';
import { TextField, Typography, InputAdornment } from '@mui/material'
import { Controller } from "react-hook-form";

const InputIcon = ({ fieldName, fieldType, fieldLabel, placeholder, width, fontSize, control, Icon, error,variant,backgroundColor, defaultValue }) => {
	return (
		<FormGroup sx={{ m: 1 }}  >

			<Typography className='formText' sx={{ fontSize: fontSize ? { fontSize } : '12px' }} >{fieldLabel}</Typography>
			<Controller
				name={fieldName}
				control={control ? control : null}
				render={({ field }) => <TextField
				
					fullWidth
					defaultValue={defaultValue}
					error={error?.message ? true : false}
					helperText={error?.message}
					{...field}
					
					variant="standard"
					className='formText'
					sx={{
						"& .MuiFilledInput-underline:before": {
							borderBottom: "none"
						},
						"& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
							borderBottom: "none",

						}, borderRadius: '0px',
						"& .MuiFilledInput-root": {
							paddingRight: "0px",
							marginLeft:'1px',
						},
						variant:variant?{variant}: 'standard',
						backgroundColor:backgroundColor?{backgroundColor}: '#F6F6F6'
					}}
					type={fieldType} placeholder={placeholder}
					InputProps={{
		
						disableUnderline: true,
						endAdornment: (
							<InputAdornment  >
								{Icon}
							</InputAdornment>
						),
					}}
				/>}
			/>
		</FormGroup>
	)
}

export default InputIcon
