

import React from 'react'
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { makeStyles } from '@mui/styles';
import { FormGroup, Typography } from '@mui/material';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles(() => ({
	noBorder: {
	  borderRadius: 0,
	  border: 0,
	},
	resize:{
		fontSize:12,
        height: 15,
  
	},
}));

const CustomDate = ({label, changeDate,minDate}) => {

    const [value, setValue] = React.useState(null);
    const changeDateValue = (value) => {
        setValue(value)
        changeDate(value)
    }

    const classes = useStyles();

    return (
            <FormGroup style={{ marginRight: 5, marginLeft: 5, marginBottom: 1 }} >
            <Typography className='formText' sx={{ fontSize:  '12px' }} >{label}</Typography>
        <DatePicker
            autoComplete='off'
            selected={value}
            minDate={minDate ? minDate : null}
            dateFormat='dd-MM-yyyy'
            onChange={(newValue) => {
              
                changeDateValue(newValue);
            }}
            
            customInput={<TextField
                className='dateClassfeild'
                fullWidth
                size='small'
                variant='standard'
                style={{ backgroundColor: '#fff',height:15, padding: 5, border: '1px solid #ccc', borderRadius: 4, width: '95%' ,display:'flex',alignItems:'center'}}
                sx={{
                    "& .MuiFilledInput-underline:before": {
                        borderBottom: "none"
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom: "none"
                    }, borderRadius: '0px'
                }}
                placeholder={"fieldLabel"}
                InputProps={{ 
                    disableUnderline: true, 
                    classes: {
                        input: classes.resize
                    }
                }}
            />}
        />
        </FormGroup>
    )
}

export default CustomDate