import { 
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    PROFILE_LIST_SUCCESS,
    PROFILE_LIST_FAIL,
    RESET_ERROR,
    SUBUSER_UPDATE_SUCCESS
} from "../constants/profileConstants";

export  const profileReducer = (state = { }, action) => {
    switch(action.type){

        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                updateSuccess: true
            }
        
        case UPDATE_PROFILE_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                updatepasswordSuccess: true
            }
        
        case UPDATE_PASSWORD_FAIL:
            return {
                ...state,
                error: action.payload.message
            }          
        case PROFILE_LIST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
         case PROFILE_LIST_SUCCESS:
            return {
                ...state,
                profileLists: action.payload
            }  
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                updateSuccess: null,
                updatepasswordSuccess:null,
                subUserUpdate: null
            }

        case SUBUSER_UPDATE_SUCCESS:
            return{
                ...state,
                subUserUpdate: true
            }

        
        default:
            return state;
    }
}