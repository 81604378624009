export const RESET_ERROR = 'RESET_ERROR';
export const LOADING = 'LOADING';
export const TRANSACTION_LIST_SUCCESS = 'TRANSACTION_LIST_SUCCESS';
export const TRANSACTION_LIST_FAIL = 'TRANSACTION_LIST_FAIL';
export const NEW_TOPUP_SUCCESS = 'NEW_TOPUP_SUCCESS';
export const NEW_TOPUP_FAIL = 'NEW_TOPUP_FAIL';
export const BANK_LIST_SUCCESS = 'BANK_LIST_SUCCESS';
export const BANK_LIST_FAIL = 'BANK_LIST_FAIL';



