import { GETT_SELECTED_VEHICLE, PRICE_ESTIMATION_FAIL, PRICE_ESTIMATION_SUCCESS, PRODUCT_LIST_SUCCESS, RESET_GETT, TOKEN_FAIL, TOKEN_SUCCESS } from "../constants/gettConstants";

export  const gettReducer = (state = { 
    error: null, 
    products: [
        {
            "product_id": "8a0bb610-d5f8-408c-aad7-8efe2c0d15a8",
            "name": "Gett Express",
            "icon": "https://static.gett.com/services/media/media_files/25955/0000_Get-Express_Selection.png",
            "description": "Skoda Octavia, Skoda Fabia, Kia Cee'd",
            "eta_sec": 0,
            "settings": {
                "on_demand": true,
                "pre_book": {
                    "allowed": true,
                    "pre_book_in_advance_min": 20,
                    "edit_future_order_enabled": true,
                    "edit_before_scheduled_at_min": 30,
                    "mandatory_flight_details_airport_rides": false
                },
                "riders": 4,
                "stops": 3,
                "seats": 4,
                "note_to_driver": {
                    "allowed": false,
                    "mandatory": false
                },
                "mandatory_destination": true
            },
            price: {
                "quote_id": "5da18cbf-9914-4ec1-8ed6-d1da4afda25e",
                "currency": "£",
                "currency_code": "GBP",
                "price": {
                    "include_tax": false,
                    "value": "72.04"
                },
                "tech_fee": {
                    "applicable": false,
                    "value": 0
                }
            }
        }
    ] 
}, action) => {
    switch(action.type){
        case TOKEN_SUCCESS:
            return{
                ...state,
                gettToken : action.payload
            }

        case TOKEN_FAIL:
        case PRICE_ESTIMATION_FAIL:
            return{
                ...state,
                error: action.payload
            }

        case RESET_GETT:
            return{
                ...state,
                error: null
            }

        case PRODUCT_LIST_SUCCESS:
            return{
                ...state,
                products: action.payload
            }

        case PRICE_ESTIMATION_SUCCESS:
            let products = state?.products?.map(product => {
                if(product?.product_id === action.payload.productId){
                    return {
                        ...product,
                        price: action.payload?.estimation
                    }
                }
                else{
                    return product
                }
            })
            return{
                ...state,
                products: products
            }

        case GETT_SELECTED_VEHICLE:
            return{
                ...state,
                selectedVehicle: action.payload
            }

        default:
            return state;
    }
}