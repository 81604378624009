import { FormGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { makeStyles } from '@mui/styles';
import { BOOKING_INPUT, LOADING } from '../../Redux/constants/bookingConstants';
import customAxios from '../../CustomAxios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import FileInput from '../../components/FileInput';


const useStyles = makeStyles(() => ({
    noBorder: {
        borderRadius: 0,
        border: 0,
    },
    resize: {
        fontSize: 12,
        height: 22,
        paddingLeft: 2
    },
}));

const Itinary = ({ control, error }) => {

    const dispatch = useDispatch()

    const classes = useStyles();

    const [files, setFiles] = useState(null)

    const uploadFiles = async (file) => {
        dispatch({
            type: LOADING,
            payload: true
        })

        let formData = new FormData();
        formData.append("itenary", file)

        await customAxios.post(`uploaditenary`, formData)
            .then(async response => {

                dispatch({
                    type: LOADING,
                    payload: false
                })


                dispatch({
                    type: BOOKING_INPUT,
                    payload: {
                        prop: 'itenary',
                        value: response?.data?.data
                    }
                })

            })
            .catch(async error => {

                dispatch({
                    type: LOADING,
                    payload: false
                })

                toast.error(error)

            });
    }

    return (
        <FormGroup style={{ marginRight: 5, marginLeft: 5, marginBottom: 1 }}  >
            {/* <Typography
                className='formText'
                sx={{ fontSize: '12px' }} >{"Itinerary"}</Typography>
            <TextField
                size='small'
                InputProps={{
                    disableUnderline: true,
                    classes: {
                        input: classes.resize
                    },
                    
                }}
                inputProps={{
                    accept:"application/pdf"
                }}
                onChange={e => uploadFiles(e.target.files[0])}
                style={{ backgroundColor: '#F6F6F6', justifyContent: 'center' }}
                variant='standard' className='formText'
                sx={{
                    "& .MuiFilledInput-underline:before": {
                        borderBottom: "none"
                    },
                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom: "none"
                    },
                }}
                type={"file"}
            ></TextField> */}
            <FileInput
                fieldLabel="Itenary"
                fieldName="Itenary"
                fieldType={"file"}
                control={control}
                error={error}
                onChange={e => uploadFiles(e.target.files[0])}
            />
        </FormGroup>
    )
}

export default Itinary