import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';

const DriverRegistration = () => {

    return (
        <div>
            <HomeHeader/>
            <HomeTopBar/>
            <Banner heading="DRIVER REGISTRATION"/>    
        </div>
    )
}

export default DriverRegistration
