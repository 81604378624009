import React from 'react'
import { Grid,Modal,Typography,Button,Box ,FormGroup,IconButton} from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import CloseIcon from '@mui/icons-material/Close';

const EditRole = ({openEditModal, closeEditModal, roles}) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius:'25px',
        border: '1px solid #fff',
        p: 4,
        height:'671',
        width:'35%'
      };
    const schema = yup.object({
        date: yup.string().required('User name required'),
        role: yup.string().required('Role required'),
    }).required();

    const { register, handleSubmit, control, formState: { errors }, setValue, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: roles
    });
    const onSubmit = (data) => {

        //dispatch(addTopUp(formData))
    };

    return (

        <Modal open={openEditModal} onClose={closeEditModal}  >
            <Box sx={style} >
                <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'center',padding:'1%'}}>
                    <Typography id="modal-modal-title" className='modalHeader' flex={0.95} display="flex" justifyContent="center" >
                        EDIT USER
                    </Typography>
                    <IconButton onClick={closeEditModal} style={{ alignSelf:'flex-end', flex: 0.05 }}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <form action="/somewhere.html">
                    <FormGroup >
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={6}>
                                <InputField 
                                    fieldLabel="Role" 
                                    fieldName="role"
                                    control={control} 
                                    error={errors.role} 
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputField
                                    fieldLabel="Date"
                                    fieldName="date"
                                    control={control}
                                    error={errors.date}
                                />
                            </Grid>                           
                        </Grid>
                        
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'center',paddingTop:20}}>
                            <Button type="submit" variant="filled" style={{backgroundColor:'#333333',borderRadius:15,height:50,width:120}}>
                            <Typography className='buttonText'>UPDATE</Typography></Button>
                        </div>
                    </FormGroup>
                </form>
            </Box>
              
        </Modal>
    )
}

export default EditRole
