export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_FAIL = 'TOKEN_FAIL';
export const RESET_GETT = 'RESET_GETT'

export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

export const PRICE_ESTIMATION_SUCCESS = 'PRICE_ESTIMATION_SUCCESS';
export const PRICE_ESTIMATION_FAIL = 'PRICE_ESTIMATION_FAIL';

export const GETT_SELECTED_VEHICLE = 'GETT_SELECTED_VEHICLE'