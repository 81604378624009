import { 
    NEW_TOPUP_SUCCESS,
    NEW_TOPUP_FAIL,
    TRANSACTION_LIST_SUCCESS,
    TRANSACTION_LIST_FAIL,
    BANK_LIST_SUCCESS,
    BANK_LIST_FAIL,
    RESET_ERROR,
    LOADING
} from "../constants/topupConstants";

export  const topupReducer = (state = { }, action) => {
    switch(action.type){

        case LOADING:
            return{
                ...state,
                loading: action.payload
            }

        case NEW_TOPUP_SUCCESS:
            return {
                ...state,
                topupLists : state.topupLists ? [...state.topupLists, action.payload.topup] : [ action.payload.topup ],
                newTopup: true
            }
        case TRANSACTION_LIST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
        case NEW_TOPUP_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
         case TRANSACTION_LIST_SUCCESS:
            return {
                ...state,
                transactionLists: action.payload
            }    
        case BANK_LIST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
         case BANK_LIST_SUCCESS:
            return {
                ...state,
                bankLists: action.payload
            }  
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                newTopup: null,
            }

        
        default:
            return state;
    }
}