import { 
    MY_TRANSFER_AVAILABILITY_FAIL, 
    MY_TRANSFER_AVAILABILITY_SUCCESS, 
    MY_TRANSFER_SELECTED_VEHICLE, 
    RESET_MYTRANSFER 
} from "../constants/myTransferConstants";


export  const myTransferReducer = (state = { error: null }, action) => {
    switch(action.type){
        case MY_TRANSFER_AVAILABILITY_SUCCESS:
            return{
                ...state,
                vehicleAvailability : action.payload
            }

        case MY_TRANSFER_AVAILABILITY_FAIL:
            return{
                ...state,
                error: action.payload
            }

        case MY_TRANSFER_SELECTED_VEHICLE:
            return{
                ...state,
                selectedVehicle: action.payload
            }

        case RESET_MYTRANSFER:
            return{
                ...state,
                error: null
            }

        default:
            return state;
    }
}