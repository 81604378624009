import React, { useState } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './style.scss'
import { Button,Grid,Box } from '@mui/material'
import Brightness7Icon from '@mui/icons-material/Brightness7';
import TopBar from '../../components/TopBar'
import RoleList from './RoleList'
import AddRole from './AddRole'
const Role = ({head}) => {

    return (
        <Layout head={head}>
            {/* <TopBar activeMenu="7"/>  */}
            
            <Box xs={6} md={5} className='userBox'>
               <RoleList />
                
            </Box>
            
        </Layout>
    )
}

export default Role
