import { 
    NEW_MARKUP_SUCCESS,
    NEW_MARKUP_FAIL,
    MARKUP_LIST_SUCCESS,
    MARKUP_LIST_FAIL,
    RESET_ERROR
} from "../constants/markupConstants";

export  const markupReducer = (state = { }, action) => {
    switch(action.type){

        case NEW_MARKUP_SUCCESS:
            return {
                ...state,
                markupLists : action.payload,
                newMarkup: true
            }
        
        case NEW_MARKUP_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
          
        case MARKUP_LIST_FAIL:
            return {
                ...state,
                error: action.payload.message
            }
         case MARKUP_LIST_SUCCESS:
            return {
                ...state,
                markupLists: action.payload[0]
            }  
        case RESET_ERROR:
            return {
                ...state,
                error: null,
                newMarkup: null,
            }

        
        default:
            return state;
    }
}