import { 
    LOADING, 
    NEW_MARKUP_SUCCESS, 
    NEW_MARKUP_FAIL,
    MARKUP_LIST_SUCCESS,
    MARKUP_LIST_FAIL

} from "../constants/markupConstants";
import axios from '../../CustomAxios'

//Add Markup
export const addMarkup = (formData) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`markup`, formData)
    .then(async response => {

        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: NEW_MARKUP_SUCCESS,
            payload: response.data.data
        })
       
    })
    .catch(async error => {
  

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: NEW_MARKUP_FAIL,
            payload: error
        })

    });
}



//Get MARKUPLIST
export const getMarkup = (id) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`markup/${id}`)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
       

        dispatch({
            type: MARKUP_LIST_SUCCESS,
            payload: response.data.data
        })
    })
    .catch(async error => {
        dispatch({
            type: LOADING,
            payload: false
        })
        dispatch({
            type: MARKUP_LIST_FAIL,
            payload: error
        })
    });
}



