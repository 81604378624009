import React from 'react'
import {Modal,Typography,Button,Box } from '@mui/material'
import {useLocation} from 'react-router-dom';
const DeleteUser = ({openDeleteModal, closeDeleteModal,users}) => {
    const location = useLocation();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius:'25px',
        border: '1px solid #fff',
        p: 4,
        height:'auto',
        width:'500px'
      };

    return (
        <Modal open={openDeleteModal} onClose={closeDeleteModal}  >
            <Box sx={style} >
                <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'center',padding:'1%'}}>
                    <Typography id="modal-modal-title" className='modalHeader'  >
                    DELETE USER
                    </Typography>
                </div>

                <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'center',paddingTop:20,alignItems:'center'}}>
                    <Typography className='loctextbold'>Are you sure you want to delete this user?</Typography>
                </div> 

                <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'center',paddingTop:20}}>
                    <Button type="submit" variant="filled" style={{backgroundColor:'#333333',borderRadius:15,height:50,width:120,marginRight:'4px'}} onClick={closeDeleteModal}>
                    <Typography className='buttonText'>YES</Typography></Button>
                    <Button type="submit" variant="filled" style={{backgroundColor:'#333333',borderRadius:15,height:50,width:120}} onClick={closeDeleteModal}>
                    <Typography className='buttonText'>NO</Typography></Button>
                </div>
            </Box>      
        </Modal>
    )
}

export default DeleteUser
